import normalizeForSearch from "normalize-for-search"
import React, { ReactElement } from "react"
import { Link } from "react-router-dom"
import { Link as ExternalLink, styled, TextField } from "@mui/material"

interface StylelessLinkProps {
  children: ReactElement | string | (ReactElement | string)[]
  to?: string
  external?: boolean
}

export const StylelessLink = ({ children, to, external = false }: StylelessLinkProps): React.ReactElement => {
  return to ? (
    external ? (
      <ExternalLink href={to} style={{ textDecoration: "inherit", color: "inherit", cursor: "pointer" }}>
        {children}
      </ExternalLink>
    ) : (
      <Link to={to} style={{ textDecoration: "inherit", color: "inherit", cursor: "pointer" }}>
        {children}
      </Link>
    )
  ) : (
    <div>{children}</div>
  )
}

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
}))

export const FormatDate = (date: string): string => {
  const year = date.slice(0, 4)
  const month = parseInt(date.slice(5, 7))
  const day = date.slice(8, 10)
  return `${day} ${months[month - 1]} ${year}`
}

export const formatSearch = (search: string): string => {
  return normalizeForSearch(search).replace(" ", "")
}

interface FormatParagraphsProps {
  content: string
}

export const FormatParagraphs = ({ content }: FormatParagraphsProps): React.ReactElement => {
  return (
    <>
      {" "}
      {content.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </>
  )
}

const months = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
]

export function delay(milliseconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export const CONSTANTS = {
  contact: {
    contact: { mail: "contact@hyris.tv" },
    presta: { mail: "hyris.prestations@centralesupelec.fr", name: "Alex Le Tollec" },
    prez: { mail: "florentin.jasson@student-cs.fr", name: "Florentin Jasson" },
    matos: { mail: "alix.surun@student-cs.fr", name: "Alix Surun" },
    rej: { mail: "sophie.petit@student-cs.fr", name: "Sophie Petit" },
    geek: { mail: "geeks@hyris.tv" },
  },
  version: "1.1.4",
}
